<template>
<!--  <div class="pager flex">-->
<!--    <div class="page" @click="changePage('prev')"><</div>-->
<!--    <div style="margin-left: 10px;margin-right: 10px">{{currentPage}}/{{pageCount}}</div>-->
<!--    <div class="page" @click="changePage('next')">  > </div>-->
<!--    <div style="margin-left: 10px">共{{total}}条</div>-->
<!--  </div>-->
  <div class="pagerbox">
    <el-pagination
        background
        layout="prev, pager, next,total"
        @current-change="pageChange"
        :page-size="pageSize"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "pager",
  props: {
    total: {
      default: 0,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 5
    },
  },
  data(){
    return {
currentPage:this.page
    }
  },
  computed:{
    pageCount(){
      let count=Math.ceil(this.total/this.count)
      return count
    }
  },
 methods:{
   pageChange(e){
     this.currentPage=e
     this.$emit('pagination', { page: this.currentPage, pageCount: this.pageCount })
   },
   changePage(t){
     if(t=="prev"){
       if(this.currentPage<=1){return}
       this.currentPage--
       if(this.currentPage<1)this.currentPage=1
     }
     if(t=="next"){
       if(this.currentPage>=this.pageCount){return}
       this.currentPage++
       if(this.currentPage>this.pageCount)this.currentPage=this.pageCount
     }
     this.$emit('pagination', { page: this.currentPage, pageCount: this.pageCount })
   },
 }
}
</script>

<style scoped>
>>>.el-pagination button, >>>.el-pagination span:not([class*=suffix]){
  font-size: 18px;
  color: #fff;
}
>>>.el-pagination__total{color: #00B7FF;font-size: 18px}
>>>.el-pagination.is-background .el-pager li{
  color: white;
  background-color: #123E6B;
}
>>>.el-pagination.is-background .btn-next, >>>.el-pagination.is-background .btn-prev, >>>.el-pagination.is-background .el-pager li{
  color: white;
  background-color: #123E6B;
}
>>>.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color:rgba(2,119,250,1)
}

div{user-select: none}
.pagerbox{text-align: center}
/*.pager{align-items: center;color: #fff;justify-content: center}*/
.page{
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background:rgba(1, 4, 41, 0.8);
  border-radius: 3px;
  border: 1px solid #1AC9FF;
  color: #16A6D9;
  text-align: center;}
.flex{display: flex}
</style>
