<template>
    <div class="control">
        <div class="control-content">
            <div class="control-header">
                <div class="control-header-back" @click="$refs.dialog.open()">
                    <img src="../assets/smart/home/dialog2.png" alt="" />
                    <span>返回</span>
                </div>
                <span class="control-header-title">今日风险管控</span>
            </div>
            <div class="control-body">
                <div class="control-body-content">
                    <!-- 标题 -->
                    <div class="control-body-header">
                        <span> 今日风险管控</span>
                        <img src="../assets/regulation/yd.png" alt="" />
                    </div>
                    <!-- 中间内容 -->
                    <div class="control-total">
                        <!-- 自主预警数 专项检查 飞行检查 -->
                        <div class="examine commonstyle">
                            <div class="commonbox">
                                <span>7</span>
                                <span>自动预警书</span>
                            </div>
                            <div class="commonbox">
                                <span>0</span>
                                <span>专项检查</span>
                            </div>
                            <div class="commonbox">
                                <span>0</span>
                                <span>飞行检查</span>
                            </div>
                        </div>
                        <!-- 已自主整改 待整改 责令整改 -->
                        <div class="examine commonstyle">
                            <div class="commonbox">
                                <span>5</span>
                                <span>已自主整改</span>
                            </div>
                            <div class="commonbox">
                                <span>0</span>
                                <span>待整改</span>
                            </div>
                            <div class="commonbox">
                                <span>1</span>
                                <span>责令整改</span>
                            </div>
                        </div>
                        <!-- 自动下发 审核下发 -->
                        <div class="examine commonstyle">
                            <div class="commonbox">
                                <span>5</span>
                                <span>自动下发</span>
                            </div>
                            <div class="commonbox">
                                <span>1</span>
                                <span>审核下发</span>
                            </div>
                        </div>
                        <!-- 检查处置 -->
                        <div class="examine commonstyle">
                            <div class="commonbox">
                                <span>5</span>
                                <span>检查处置</span>
                            </div>
                        </div>
                        <!-- 联动处置 -->
                        <div class="examine commonstyle">
                            <div class="commonbox">
                                <span>5</span>
                                <span>联动处置</span>
                            </div>
                        </div>
                        <!-- 扣分结果 -->
                        <div class="examine commonstyle">
                            <div class="commonbox">
                                <span>5</span>
                                <span>扣分结果</span>
                            </div>
                        </div>
                        <!-- 未处置 -->
                        <div class="examine commonstyle">
                            <div class="commonbox">
                                <span>5</span>
                                <span>未处置</span>
                            </div>
                        </div>
                    </div>
                    <!-- 表格 -->
                    <div class="control-table">
                        <table>
                            <tr class="first">
                                <td>序号</td>
                                <td style="width:330px">企业名称</td>
                                <td>风险名称</td>
                                <td>发现方式</td>
                                <td>发现时间</td>
                                <td>违规内容</td>
                                <td>当前状态</td>
                                <td>当前操作</td>
                            </tr>
                            <tr class="second" v-for="(item, index) in filterList" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td style="line-height:30px">{{ item.name }}</td>
                                <td>{{ item.riskName }}</td>
                                <td>{{ item.founType }}</td>
                                <td>{{ item.foundTime }}</td>
                                <td>{{ item.content }}</td>
                                <td>
                                    <span v-if="item.currentStatus == 1" style="color:#1DCE3A"
                                        >已通知</span
                                    >
                                </td>
                                <td class="audit">
                                    <span>审核下发</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="footer-page">
                        <Pager :total="tableList.length" @pagination="pagination"></Pager>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pager from "./components/pager";
export default {
    components: {
        Pager,
    },
    data() {
        return {
            tableList: [
                {
                    name: "金华市江南药都医药连锁有限公司浦江亚太路药店",
                    riskName: "温湿度超标",
                    founType: "自动预警",
                    foundTime: "2022-05-24 12:12:23",
                    content: "湿度85%",
                    currentStatus: 1,
                },
                {
                    name: "金华市江南药都医药连锁有限公司浦江亚太路药店",
                    riskName: "温湿度超标",
                    founType: "自动预警",
                    foundTime: "2022-05-24 12:12:23",
                    content: "湿度85%",
                    currentStatus: 1,
                },
                {
                    name: "金华市江南药都医药连锁有限公司浦江亚太路药店",
                    riskName: "温湿度超标",
                    founType: "自动预警",
                    foundTime: "2022-05-24 12:12:23",
                    content: "湿度85%",
                    currentStatus: 1,
                },
                {
                    name: "金华市江南药都医药连锁有限公司浦江亚太路药店",
                    riskName: "温湿度超标",
                    founType: "自动预警",
                    foundTime: "2022-05-24 12:12:23",
                    content: "湿度85%",
                    currentStatus: 1,
                },
                {
                    name: "金华市江南药都医药连锁有限公司浦江亚太路药店",
                    riskName: "温湿度超标",
                    founType: "自动预警",
                    foundTime: "2022-05-24 12:12:23",
                    content: "湿度85%",
                    currentStatus: 1,
                },
                {
                    name: "金华市江南药都医药连锁有限公司浦江亚太路药店",
                    riskName: "温湿度超标",
                    founType: "自动预警",
                    foundTime: "2022-05-24 12:12:23",
                    content: "湿度85%",
                    currentStatus: 1,
                },
                {
                    name: "金华市江南药都医药连锁有限公司浦江亚太路药店",
                    riskName: "温湿度超标",
                    founType: "自动预警",
                    foundTime: "2022-05-24 12:12:23",
                    content: "湿度85%",
                    currentStatus: 1,
                },
                {
                    name: "金华市江南药都医药连锁有限公司浦江亚太路药店",
                    riskName: "温湿度超标",
                    founType: "自动预警",
                    foundTime: "2022-05-24 12:12:23",
                    content: "湿度85%",
                    currentStatus: 1,
                },
                {
                    name: "金华市江南药都医药连锁有限公司浦江亚太路药店",
                    riskName: "温湿度超标",
                    founType: "自动预警",
                    foundTime: "2022-05-24 12:12:23",
                    content: "湿度85%",
                    currentStatus: 1,
                },
                {
                    name: "金华市江南药都医药连锁有限公司浦江亚太路药店",
                    riskName: "温湿度超标",
                    founType: "自动预警",
                    foundTime: "2022-05-24 12:12:23",
                    content: "湿度85%",
                    currentStatus: 1,
                },
            ],
            newArr: [],
            page: {
                page: 1,
                pageSize: 5,
            },
        };
    },
    methods: {
        pagination(value) {
            this.page.page = value.page;
        },
    },
    computed: {
        filterList() {
            const end = this.page.page * this.page.pageSize;
            const start = end - this.page.pageSize;
            return this.tableList.slice(start, end);
        },
    },
};
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.control {
    width: 1920px;
    background-image: url("../assets/regulation/bg.jpg");
    height: 1080px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .control-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .control-header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 65px;
            width: 100%;
            background-image: url("../assets/regulation/bt.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .control-header-back {
                position: absolute;
                left: 46px;
                top: 60%;
                transform: translateY(-50%);
                span {
                    font-size: 24px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #00d7fe;
                    line-height: 26px;
                    margin: 0 30px 0 10px;
                }
                img {
                    width: 23px;
                    height: 19px;
                }
            }
            .control-header-title {
                line-height: 38px;
                font-size: 40px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #ffffff;
                line-height: 26px;
            }
        }
        .control-body {
            flex: 1;
            padding: 0 260px;
            .control-body-content {
                position: relative;
                display: flex;
                flex-direction: column;
                margin-top: 35px;
                width: 1490px;
                height: 904px;
                overflow: hidden;
                background-image: url("../assets/control/bg.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                .control-body-header {
                    display: flex;
                    align-items: center;
                    padding: 19px 0 0 19px;
                    span {
                        line-height: 28px;
                        font-size: 28px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #00d7fe;
                        letter-spacing: 6px;
                    }
                    img {
                        width: 95px;
                        height: 10px;
                    }
                }
                .control-total {
                    width: 1410px;
                    margin: 35px auto;
                    height: 221px;
                    background-image: url("../assets/control/centerbg.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .commonstyle {
                        width: 170px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .commonbox {
                            width: 170px;
                            height: 67px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            span {
                                &:first-child {
                                    font-size: 32px;
                                    font-family: Source Han Sans CN;
                                    font-weight: bold;
                                    color: #fbc902;
                                    line-height: 19px;
                                }
                                &:last-child {
                                    font-size: 20px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    color: #eeeeee;
                                    line-height: 19px;
                                    margin-top: 4px;
                                }
                            }
                        }
                    }
                }
                .control-table {
                    padding: 0 40px;
                    margin-top: 30px;
                    overflow-y: auto;
                    table {
                        height: 100%;
                        width: 100%;
                        border-collapse: collapse;
                        .first {
                            height: 55px;
                            background-image: url("../assets/regulation/表头.png");
                            text-align: center;
                            td {
                                line-height: 55px;
                                font-size: 22px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #ffffff;
                                vertical-align: middle;
                            }
                        }
                        .second {
                            text-align: center;
                            height: 80px;
                            &:nth-child(2n + 1) {
                                background: url("../assets/regulation/表单.png");
                                background-size: 100% 100%;
                                background-repeat: no-repeat;
                            }
                            td {
                                height: 80px;
                                font-size: 22px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #ffffff;
                                vertical-align: middle;
                            }
                            .audit {
                                span {
                                    width: 120px;
                                    height: 45px;
                                    background: #0277fa;
                                    border-radius: 4px;
                                    font-size: 20px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin: auto;
                                }
                            }
                        }
                    }
                }
            }
            .footer-page {
                position: absolute;
                margin-top: 25px;
                left: 50%;
                bottom: 40px;
                transform: translateX(-50%);
            }
        }
    }
}
</style>
